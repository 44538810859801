
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLangObj } from '../../reducers/langReducer.js';
import SliderElement from '../slider/SliderElement.js';
import DocumentSlider from '../slider/DocumentSlider.js';


var links = [

  {
    url:"https://www.youtube.com",
    title:"YouTube",
    image:"https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/512px-YouTube_full-color_icon_%282017%29.svg.png"
    
  },  
  {
    url:"https://backapalankavesti.com/",
    title:"BAP Dešavanja",
    image:"https://backapalankavesti.com/wp-content/uploads/2018/08/logo_50.png"
    
  },  


]

function MainLinks(props) {
  const langObj = useSelector(selectLangObj);

  function getItems(data){
    return data.map(e => {

      return (
        <SliderElement auto={false} key={e.title} data={e} size={"md"}></SliderElement>
      )
    })
  }
  return (
     /* {
            "рбр": 24,
            "тип": "Фото",
            "име": "АЦ0001",
            "наслов": "фотографија",
            "датум": "2024-10-29",
            "прикупио": "С. Тришић",
            "период": 0,
            "линк": "https://i.ibb.co/sRBNGHV/img002.jpg",
            "линкП": "https://ibb.co/9gM1Ltr"
        }, */
           <DocumentSlider dynamic={false} title={langObj["Links"]} getItems={()=>getItems(links)}></DocumentSlider>
  );
}

export default MainLinks;
