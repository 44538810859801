import { GlobeEuropeAfricaIcon } from '@heroicons/react/24/outline'
import LangButton from './components/lang/LangButton.js';
import { selectLang, setLang, selectLangObj } from './reducers/langReducer.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router';


function Header() {
  var winmd = window.matchMedia("(min-width: 768px)")

  const [showLang, setShowLang] = useState(false);
  const [mdLang, setMdLang] = useState(winmd.matches);
  const lang = useSelector(selectLang);
  const langObj = useSelector(selectLangObj);


  const dispatch = useDispatch();
  useEffect(() => {
    if (typeof window === 'undefined') {
        return;
    }

    function autoResize() {
        var winmd = window.matchMedia("(min-width: 768px)")
        setShowLang(false);

        setMdLang(winmd.matches);
    }

    window.addEventListener('resize', autoResize);

    // This is likely unnecessary, as the initial state should capture
    // the size, however if a resize occurs between initial state set by
    // React and before the event listener is attached, this
    // will just make sure it captures that.

    // Return a function to disconnect the event listener
    return () => window.removeEventListener('resize', autoResize);
}, [])

  return (




    <header className="App-header bg-beige-500  flex-shrink-0">
      <div class="flex flex-row justify-start items-stretch flex-shrink-0 gap-3 w-full">

        <NavLink to="/" end>

          <div class="relative h-20 w-20">
            <img src={require("./images/logoIconCropped.jpg")} className='h-full w-full absolute top-0 left-0 object-cover'>

            </img>
          </div>
        </NavLink>
        <NavLink to="/" end className={" self-center"}>
          <h4 className='md:text-2xl text-lgs text-slate-600 '>
            {langObj["AppTitle"]}
          </h4>
        </NavLink>

        <div class="flex flex-row justify-start items-stretch  flex-grow flex-shrink-0">

          {/* <h1>Arhiva Gajdobra</h1> */}
          <div className='flex-grow'></div>
          <div className={(mdLang ? "" : "relative") + " flex flex-row justify-start items-stretch"}>

            {mdLang ||

              <button className={((mdLang || showLang) ? "bg-beige-600 text-white " : " text-slate-600 ") + ' self-stretch px-3'} onClick={() => {
                setShowLang(!showLang);
              }}>
                <GlobeEuropeAfricaIcon className='size-7  '></GlobeEuropeAfricaIcon>
              </button>
            }
            {(

              <div class={(mdLang?'':showLang ? " h-full " : "  h-0 ") + (mdLang ? "" : " overflow-hidden absolute top-full right-0 ") + "  transition-all duration-300 z-40 flex flex-row justify-start items-stretch"}>



                {/* <LangButton onClick={() => {dispatch(setLang("sr"))}} name="SR" selected={lang === "sr"} /> */}
                <LangButton onClick={() => { dispatch(setLang("src")) }} name="СР" selected={lang === "src"} />
                <LangButton onClick={() => { dispatch(setLang("en")) }} name="EN" selected={lang === "en"} />
                <LangButton onClick={() => { dispatch(setLang("de")) }} name="DE" selected={lang === "de"} />


              </div>
            )

            }



          </div>





        </div>
      </div>
    </header>

  )
}

export default Header;