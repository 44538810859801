import React from 'react'
import { useSelector } from 'react-redux';
import { selectLangObj } from '../../../reducers/langReducer';
import { ChevronLeftIcon, ChevronRightIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline';

export default function Pagination({ pagenum, totalpages, count, getItems, onPageSet }) {


    var pages = [];

    const langObj = useSelector(selectLangObj);


    let startGood = pagenum - 3 > 0;
    let endGood = pagenum + 3 > totalpages;
    let start = startGood ? pagenum - 3 : 1;
    let end = endGood ? totalpages : pagenum + 3;

    for (let index = start; index < end; index++) {


        pages.push({
            name: index,
            index: index,
            active: index == pagenum
        })
    }

    var onPageSetInt = (e) => {
        if(e<1 || e >= totalpages){
            return;
        }
        
        onPageSet(e)
    }

    var setPage = (e) => {
        
        return el => onPageSet(e.index)
    }

    return (
        <div className='h-full flex flex-col'>

            <div className='flex-grow min-h-0 overflow-y-auto flex flex-col items-center gap-3'>
                <div className='self-start text-white'>
                    {count ? (count + " " + langObj["RowsFound"]) : langObj["NothingFound"]}
                </div>


                {
                    count ? getItems() : ""
                }

            </div>
            {
                count ? (

                    <div className='flex p-3 text-slate-600 gap-3 items-center'>
                        <button>
                            <ChevronLeftIcon onClick={el => onPageSetInt(pagenum - 1)} className='size-6'></ChevronLeftIcon>

                        </button>

                        {!startGood ? "" : <EllipsisHorizontalIcon className='size-6'></EllipsisHorizontalIcon>}
                        {
                            pages.map(e => (<button onClick={setPage(e)} className={(e.active ? "bg-beige-600 text-slate-600" : "bg-slate-700 text-white") + '  rounded-full size-6 text-sm '}>{e.name}</button>))

                        }

                        {endGood ? "" : <EllipsisHorizontalIcon className='size-6'></EllipsisHorizontalIcon>}
                        <button>
                            <ChevronRightIcon onClick={el => onPageSetInt(pagenum + 1)} className='size-6'></ChevronRightIcon>

                        </button>
                    </div>
                ) : ""}

        </div>
    )
}
