import { createSlice } from '@reduxjs/toolkit'
import { fetchRecentDocuments } from '../fetch/documents';
import { createReducer } from './createReducer';
const recentDocumentsSlice = createReducer("recentDocuments", fetchRecentDocuments);


export const selectRecentDocuments = (state) => state.recentDocuments.value
export const selectLoadingRecentDocuments = (state) => state.recentDocuments.isLoading
//export const selectPageRecentDocuments = (state) => state.recentDocuments.page;

export const {} = recentDocumentsSlice.actions

export default recentDocumentsSlice.reducer

