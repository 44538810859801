

function SquareButtonSm(props) {

  return (

    <button onClick={props.onClick} className={props.classAdd + " flex-grow flex-shrink-0 max-md::text-xs  transition-all px-1 md:px-3  flex flex-row justify-center items-center   text-white"}>
      {props.name}

    </button>
  );
}

export default SquareButtonSm;
