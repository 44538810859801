import React from 'react'
import Pagination from './Pagination'
import { selectStreets,selectPage, selectTotalCount, selectTotalPages } from "../../../reducers/streetsReducer"
import { useDispatch, useSelector } from 'react-redux';

import Row from "../Row";
import { fetchStreets } from '../../../fetch/streets';

export default function StreetsPagination({term}) {

    const streets = useSelector(selectStreets);
    const page = useSelector(selectPage);
    const count = useSelector(selectTotalCount);
    const totalPages = useSelector(selectTotalPages);
    const dispatch = useDispatch();

    var getItems = e => {

        return streets.map(a=>{ 
          
          return (<Row  photo={a.documentId} title={a.име +(a.имеОца?" ("+a.имеОца+") ":" ")+ a.презиме} text={a.улица}></Row>)


        });

      }

  return (
    <Pagination onPageSet={(e)=>{

      dispatch(fetchStreets({surname:term, page:e}));


  }} count={count} getItems={getItems} pagenum={page} totalpages={totalPages} />
  )
}
