import React from 'react'
import Pagination from './Pagination'
import { selectFamily,selectPage, selectTotalCount, selectTotalPages } from '../../../reducers/familiesReducer';

import {fetchFamilies} from "../../../fetch/family"

import Row from "../../search/Row";
import { useDispatch, useSelector } from 'react-redux';

export default function FamilyPagination({term}) {

    const family = useSelector(selectFamily);
    const page = useSelector(selectPage);
    const count = useSelector(selectTotalCount);
    const totalPages = useSelector(selectTotalPages);

    const dispatch = useDispatch();

    var getItems = e => {

        return family.map(a=>{ return (<Row photo={a.documentId} title={ a.презиме +(a.имеОца?" ("+a.имеОца+") ":" ")+a.име} text={a.насеље + ", " + a.општина + ", " + a.крај}></Row>)});

      }

  return (
    <Pagination onPageSet={(e)=>{

        dispatch(fetchFamilies({surname:term, page:e}));


    }} count={count} getItems={getItems} pagenum={page} totalpages={totalPages} />
  )
}
