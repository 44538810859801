
import SquareButton from '../components/elements/SquareButton.js';
import { useRef, useState } from 'react';

import { fetchFamilies } from '../fetch/family.js';

import { fetchPopulation } from '../fetch/population.js';
import { fetchStreets } from '../fetch/streets.js';
import { fetchSchools } from '../fetch/school.js';
import { selectLangObj } from '../reducers/langReducer.js';
import { useDispatch, useSelector } from 'react-redux';
import SearchLayout from '../components/search/SearchLayout.js';

import BooksSlider from "../components/slider_variants/BooksSlider.js"
import DocumentsSlider from "../components/slider_variants/DocumentsSlider.js"
import ImagesSlider from "../components/slider_variants/ImagesSlider.js"
import MediaSlider from "../components/slider_variants/MediaSlider.js"
import MainLinks from "../components/slider_variants/MainLinks.js"
import RecentDocuments from "../components/slider_variants/RecentDocuments.js"
import RecentPosters from "../components/slider_variants/RecentPosters.js"
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
import SquareButtonSm from '../components/elements/SquareButtonSm.js';

function Home() {

  const [searchOn, setSearchOn] = useState(false);
  const [dropOn, setDropOn] = useState(false);
  const [searchState, setSearchState] = useState("family");
  const [term, setTerm] = useState("");
  const langObj = useSelector(selectLangObj);
  const dispatch = useDispatch();
  const ref = useRef(null); // ref => { current: null }


  function bgSearch(state) {

    // if(searchState && state && state != searchState){
    //   return " bg-white text-black  ";
    // }

    return (state == searchState ? " rounded font-semi-bold backdrop-blur-xl bg-slate-700 bg-opacity-50" : _bgSearch(state) + "  font-semi-bold ");
  }
  function bgSearchSm(state) {

    // if(searchState && state && state != searchState){
    //   return " bg-white text-black  ";
    // }

    return (state == searchState ? " font-semi-bold  bg-slate-700 " : " bg-slate-500  " + "  font-semi-bold ");
  }


  function IconSearch() {
    if (!searchOn) {
      return;
    }

    let text = "";
    switch (searchState) {
      case "family":

        text = langObj["App"]["Families"];
        break;
      case "people":
        text = langObj["App"]["People"];
        break;
      case "streets":
        text = langObj["App"]["Streets"];
        break;
      case "school":
        text = langObj["App"]["School"];
        break;
      default:
        return "";
    }

    return (
      <div onClick={() => {  setDropOn(!dropOn); }} className={_bgSearch(searchState) + " cursor-pointer rounded-s  flex flex-row justify-center items-center relative  p-2 text-white"}>
        {text}
        {dropOn?(<ChevronUpIcon className='size-4'></ChevronUpIcon>):(<ChevronDownIcon className='size-4'></ChevronDownIcon>)}
        {

          searchOn &&
          <div className={(dropOn?'h-44':'h-0')+' flex flex-col absolute  top-full left-0  overflow-hidden transition-all duration-300'} >
            <SquareButtonSm onClick={() => { setSearch("family"); }} name={langObj["App"]["Families"]} classAdd={bgSearchSm("family")} />
            <SquareButtonSm onClick={() => { setSearch("people"); }} name={langObj["App"]["People"]} classAdd={bgSearchSm("people")} />
            <SquareButtonSm onClick={() => { setSearch("streets"); }} name={langObj["App"]["Streets"]} classAdd={bgSearchSm("streets")} />
            <SquareButtonSm onClick={() => { setSearch("school"); }} name={langObj["App"]["School"]} classAdd={bgSearchSm("school")} />
          </div>
        }
      </div>
    )


  }
  function Search() {
    setSearchOn(true);
    switch (searchState) {
      case "family":
        dispatch(fetchFamilies({ surname: term, page: 1 }));

        break;
      case "people":
        dispatch(fetchPopulation({ surname: term, page: 1 }))
        break;
      case "streets":
        dispatch(fetchStreets({ surname: term, page: 1 }))
        break;
      case "school":
        dispatch(fetchSchools({ surname: term, page: 1 }))
        break;
      default:
        return;
    }




  }
  function setSearch(state) {

    setSearchState(state);

    if (searchOn) {
      Search();
    }
  }

  function _bgSearch(state) {

    let stateSw = state || searchState;
    switch (stateSw) {
      case "family":
        return "bg-teal-800"
      case "people":
        return "bg-yellow-500"
      case "streets":
        return "bg-orange-400"
      case "school":
        return "bg-red-600"
      default:
        return "";
    }
  }
  function _textSearch(state) {

    let stateSw = state || searchState;
    switch (stateSw) {
      case "family":
        return "text-teal-800"
      case "people":
        return "text-yellow-500"
      case "streets":
        return "text-orange-400"
      case "school":
        return "text-red-600"
      default:
        return "";
    }
  }
  return (
    <div className='w-full' ref={ref}>

      <div className={"  justify-center transition-all flex gap-3 flex-col mb-3 h-full  w-full  items-center"} style={{ height: searchOn ? ref.current?.parentElement.offsetHeight : "80vh" }}>

        <div className={ ' justify-center w-full md:w-8/12  items-stretch md:p-6 p-3 flex flex-col gap-3 h-full'}>

          <div className={ " transition-all flex flex-row z-10 isolate"}>

            <div className={" transition-all flex flex-col md:flex-row gap-6  w-full "}>

              <div className={(searchOn ? "rounded-xl" : " rounded-tl-3xl rounded-br-3xl ") + "  backdrop-blur-lg flex flex-col items-stretch  p-6 gap-3 md:gap-6 border border-gray-600  flex-grow basis-full"}>


                <div className={(searchOn ? "opacity-0 hidden" : " opacity-100  ") + "  transition-all flex flex-col justify-around   items-stretch text-wrap  gap-3 p-6  bg-amber-300 backdrop-blur-xl  bg-opacity-50 border border-orange-950 "}>
                  <p class="text-justify text-lg md:p-3">
                    {langObj["App"]["Intro1"]}
                  </p>
                  <p class="text-start text-lg font-semibold rounded-sm md:p-3">
                    {langObj["App"]["Intro2"]}
                  </p>
                </div>
                {!searchOn &&
                  <div className='flex gap-3 justify-around items-stretch'>

                    <SquareButton onClick={() => { setSearch("family"); }} name={langObj["App"]["Families"]} classAdd={bgSearch("family")} />
                    <SquareButton onClick={() => { setSearch("people"); }} name={langObj["App"]["People"]} classAdd={bgSearch("people")} />
                    <SquareButton onClick={() => { setSearch("streets"); }} name={langObj["App"]["Streets"]} classAdd={bgSearch("streets")} />
                    <SquareButton onClick={() => { setSearch("school"); }} name={langObj["App"]["School"]} classAdd={bgSearch("school")} />
                  </div>
                }

                <div className={" flex flex-col md:flex-row gap-3 md:gap-0  justify-between items-stretch"}>
                  {IconSearch()}
                  <input placeholder="Попарић нпр." value={term} onChange={e => setTerm(e.target.value)} class={(searchOn ? "rounded-e" : "rounded") + " me-3 flex-grow basis-full shadow appearance-none border  w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"} id="username" type="text" />
                  <button onClick={() => Search()} class={(searchOn ? "" : "") + " bg-lime-800 hover:bg-lime-900 text-white p-2 focus:outline-none focus:shadow-outline"} type="button">
                    {langObj["Search"]}
                  </button>

                  {
                    searchOn &&
                    (<button onClick={() => { setSearchOn(false); }} class=" rounded-e  bg-slate-700  hover:bg-slate-900  text-white p-2  focus:outline-none focus:shadow-outline" type="button">
                      {langObj["Back"]}
                    </button>)
                  }


                </div>
              </div>

            </div>
          </div>
          {
            searchOn && (
              <SearchLayout Type={searchState} term={term}></SearchLayout>
            )
          }
        </div>
      </div>


      <div className='flex flex-col gap-6 md:p-6 p-3 border-t border-gray-600 bg-slate-500 bg-opacity-50 backdrop-blur-lg'>
        <RecentDocuments></RecentDocuments>
        <RecentPosters />
        <MediaSlider></MediaSlider>
        <BooksSlider></BooksSlider>
        <DocumentsSlider></DocumentsSlider>
        <ImagesSlider></ImagesSlider>
        <MainLinks />
      </div>


    </div>






  )


}

export default Home;