import SliderElement from "./SliderElement";
import { EllipsisHorizontalCircleIcon} from '@heroicons/react/24/outline'


function SliderCompositeElement({ data }) {


    var moreObject = {
        icon:(<EllipsisHorizontalCircleIcon className="size-10"></EllipsisHorizontalCircleIcon>),
        title:"YouTube"
        
      };

    return (


            <div className="rounded-md bg-slate-600 bg-opacity-50 w-40 md:w-80 h-40 md:h-80 overflow-hidden flex flex-col items-stretch flex-shrink-0">
                <div className=" grid grid-cols-2 md:grid-cols-3 gap-2 p-3  flex-wrap overflow-auto justify-start items-start">
                    {data.items.map(e => {

                        return (
                            <SliderElement  small={true} key={e.url} data={e}></SliderElement>
                        )
                    })}
                    <SliderElement  small={true} key={moreObject.url} data={moreObject}></SliderElement>
                </div>
                <div className="flex-grow">

                </div>
                <div className="flex flex-col   z-10">

                    <div className="text-white bg-slate-700 bg-opacity-75 backdrop-blur-sm  z-10 p-3">

                        {data.name}
                    </div>
                </div>

            </div>
        
    );
}

export default SliderCompositeElement;
