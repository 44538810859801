import { fetchDocuments } from '../fetch/documents';
import { createReducer } from './createReducer';
const documents = createReducer("documents", fetchDocuments);


export const selectDocuments = (state) => state.documents.value
export const selectLoadingDocuments = (state) => state.documents.isLoading

export const {} = documents.actions

export default documents.reducer

