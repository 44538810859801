import { createReducer, createSlice } from '@reduxjs/toolkit'
import { fetchSingleDocument } from '../fetch/singleDocument';
import { fetchAllDocuments } from '../fetch/fetchAllDocuments';
import FilterDTO from '../models/Filters.ts';

// Setting up filterProperties object with empty values
const initialFilterProperties = {
  Тип: "",
  Име: "",
  Наслов: "",
  Датум: null,
  Прикупио: "",
  Период: NaN,
  Село: "",
  Кућа: NaN,
  Презиме: "",
  Пол: "",
  ИмеОца: "",
  ИмеМајке: "",
  Супруг: "",
  Статус: "",
  УдатоПрезиме: "",
  ДевојачкоПрезиме: "",
  Позиција: "",
  ГодРођења: NaN,
  ГодСмрти: NaN,
  МестоРођења: "",
  Сахрањен: ""
};
const gridSlice = createSlice({
  name: 'grid',
  initialState: {
    isLoading: false,
    currentDocument: null,
    isError: false,
    filter:new FilterDTO(1, 20, initialFilterProperties),
    page:1,
    totalPages:0,
    totalCount: 0,
    documents:[]
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSingleDocument.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(fetchSingleDocument.fulfilled, (state, action) => {
      state.isLoading = false;
      let data = action.payload;
      state.currentDocument = data;
    })
    builder.addCase(fetchSingleDocument.rejected, (state, action) => {
      state.isError = true;
    })

    builder.addCase(fetchAllDocuments.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(fetchAllDocuments.fulfilled, (state, action) => {
      state.isLoading = false;
      let data = action.payload;
      state.filter.PageNumber = data.pageNumber;
      state.totalCount = data.totalCount;
      state.totalPages = data.totalPages;
      if (data.items && data.items.constructor === Array){
        if (state.filter.PageNumber == 1) {
          state.documents = data.items;
        } else {
          state.documents = [...state.documents, ...data.items]
        }

      }
    })
    builder.addCase(fetchAllDocuments.rejected, (state, action) => {
      state.isError = true;
    })
  },
  reducers: {

    resetGrid: state => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.filter.PageNumber = 1
    },
    setFilter: 
    {
      reducer: (state, action) => {
        state.filter = action.payload

      },
      prepare: (filter) => {
        return { payload: filter }
      }, // fallback if the payload is a falsy value
    },
      
    setPage:(state, action,) => {
      state.filter.PageNumber = action.payload;
     
    },
    resetSingleDocument:(state, action,) => {
      state.filter.currentDocument = null;
     
    }
      // action type is inferred from prepare callback
      
    
  }
})
export const selectSingleDocument = (state) => state.grid.currentDocument
export const selectDocuments = (state) => state.grid.documents
export const selectLoadingSingleDocument = (state) => state.grid.isLoading


export const selectFilter = (state) => state.grid.filter

export const selectPage = (state) => state.grid.filter?.PageNumber
export const selectTotalCount = (state) => state.grid.totalCount
export const selectTotalPages = (state) => state.grid.totalPages


export const { resetGrid, setFilter, setPage, resetSingleDocument } = gridSlice.actions

export const InitialFilterProperties = initialFilterProperties;


export default gridSlice.reducer

