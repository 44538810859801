import { fetchMedia } from '../fetch/documents';
import { createReducer } from './createReducer';
const recentDocumentsSlice = createReducer("media", fetchMedia);


export const selectMedia = (state) => state.media.value
export const selectLoadingMedia = (state) => state.media.isLoading
//export const selectPageMedia = (state) => state.recentDocuments.page;

export const {} = recentDocumentsSlice.actions

export default recentDocumentsSlice.reducer

