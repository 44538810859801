import { fetchImages } from '../../fetch/documents.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLangObj } from '../../reducers/langReducer.js';
import SliderElement from "../slider/SliderElement.js";
import SliderItem from '../../models/SliderItem.js';
import DocumentSlider from '../slider/DocumentSlider.js';
import { selectImages } from '../../reducers/imagesReducer.js';


function ImagesSlider(props) {
  const images = useSelector(selectImages);
  const dispatch = useDispatch();
  const langObj = useSelector(selectLangObj);

  const appendItems = () => {
    dispatch(fetchImages());
  }
  useEffect(() => {

    appendItems();
  }, []);

  function getItems(recentDocuments){


    return recentDocuments.map(e => { return new SliderItem( e.наслов, e.линк,e.линк,e.датум, e.прикупио); }).map(e => {

      return (
        <SliderElement auto={false} key={e.url} data={e}></SliderElement>
      )
    })
  }

  return (
    
 
    <DocumentSlider title={langObj["Images"]} appendItems={appendItems} getItems={()=>getItems(images)}  ></DocumentSlider>
  );
}

export default ImagesSlider;
