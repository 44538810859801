

function SquareButton(props) {

  return (

    <button onClick={props.onClick} className={props.classAdd + " flex-grow flex-shrink-0 text-xs md:text-xl  transition-all md:hover:scale-110 flex flex-row justify-center items-center  px-1 md:px-3 py-2 md:py-6 text-white"}>
      {props.name}

    </button>
  );
}

export default SquareButton;
