import { createSlice } from '@reduxjs/toolkit'
const loadingSlice = createSlice({
  name: 'loader',
  initialState: {
    value: false,
  },
  
  
  reducers: {


    // incremented: state => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
    // decremented: state => {
    //   state.value -= 1
    // },
    setLoading: (state, action) => {
      state.value = action.payload;
    },
  }
})
export const selectLoader = (state) => state.loader.value

export const { setLoading: setLoading } = loadingSlice.actions

export default loadingSlice.reducer

