import { useState } from "react";

import { PhotoIcon, LinkIcon, XCircleIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import { LazyLoadImage } from "react-lazy-load-image-component";

function SliderElement({ data, size, auto }) {
    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);

    const isIcon = !!(data.icon);
    const isEmpty = data.image == null;
    const isImage = data.image && (data.image.endsWith(".jpg") || data.image.endsWith(".png") || data.image.endsWith(".gif") || data.image.endsWith(".webp"));
    const isVideo = data.image && (data.image.endsWith(".mp4") || data.image.endsWith(".avi") || data.image.endsWith(".mkv"));
    const isLink = data.image && (!isImage && !isVideo);
    const imageStyle = !isImage && !loaded ? { display: "none" } : {};

    let dimension;
    let dimensionSm;
    let text;
    switch (size) {
        case "sm":
            dimension = 20;
            dimensionSm = 12;
            break;

        case "md":
            dimension = 40;
            dimensionSm = 20;
            break;

        default:
            dimension = 80
            dimensionSm = 40;

            break;
    }

    const isSmall = size === "sm";
    const isMd = size === "md";
    const isAuto = auto !== false;
    return (
        <a href={data.url} target="_blank">

            <div className={(isAuto ? " " : (" w-"+dimensionSm+" md:w-"+dimension)) + " relative rounded-md bg-slate-600 bg-opacity-50 overflow-hidden flex-shrink-0"}>

                <div className="w-full " style={{ marginBottom: "100%" }}>

                </div>


                <LazyLoadImage effect="opacity" className="absolute top-0 left-0 w-full h-full object-contain z-1" loading="lazy"  src={data.image} style={imageStyle} onError={() => setLoading(false)} onLoad={() => { setLoaded(true); setLoading(false) }}></LazyLoadImage>
                {
                    (!loading && loaded) || (<div className="absolute top-0 left-0 w-full h-full bg-white text-slate-800 z-1 flex justify-center items-center"><PhotoIcon className="size-10"></PhotoIcon></div>)

                }
                {
                    isLink && (<div className="absolute top-0 left-0 w-full h-full bg-white text-blue-950 z-1 flex justify-center items-center"><LinkIcon className="size-10"></LinkIcon></div>)

                }
                {
                    isEmpty && (<div className="absolute top-0 left-0 w-full h-full bg-white text-blue-950 z-1 flex justify-center items-center"><XCircleIcon className="size-10"></XCircleIcon></div>)

                }
                {/* {
                    isImage && loading && (

                        <div className='w-full h-full backdrop-blur-lg  absolute top-0 left-0 z-50 flex justify-center items-center'>
                            <div className="loader"></div>
                        </div>
                    )
                } */}
                {
                    isIcon && (<div className="absolute top-0 left-0 w-full h-full bg-white text-blue-950 z-1 flex justify-center items-center">{data.icon}</div>)
                }
                {/* <video className="absolute top-0 left-0 w-full h-full" onLoad={()=>setLoaded(true)}>

                    <source src={data.image} type="video/mp4" />

                </video> */}
                <div className="flex flex-col h-full w-full absolute top-0 left-0">
                    <div className="flex">

                        <div className="flex-grow"></div>

                        {data.date && (
                            <div className="rounded-bl-lg text-white bg-beige-500 p-1 text-xs md:text-base">

                                {data.date}
                            </div>)
                        }
                    </div>
                    <div className="flex-grow"></div>
                    <div className="flex md:flex-row flex-col items-stretch md:items-stretch  bg-slate-500 bg-opacity-75 backdrop-blur-sm">

                        {!isIcon && data.author && (
                            <div className="flex gap-1 items-center justify-center bg-slate-700 bg-opacity-75 text-white p-1 md:p-3 z-10  text-xs md:text-base">
                                <UserCircleIcon className="size-4 md:size-5"></UserCircleIcon>
                                {data.author}
                            </div>)
                        }
                        {!isIcon && data.title && (
                            <div className="text-white  p-1 md:p-3 flex-grow text-xs md:text-base ">

                                {data.title}
                            </div>)

                        }
                    </div>

                </div>


            </div>
        </a>

    );
}

export default SliderElement;
