import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { resetSingleDocument, selectSingleDocument } from '../reducers/gridReducer';
import { fetchSingleDocument } from '../fetch/singleDocument';
import { UserCircleIcon, MapPinIcon, CalendarDaysIcon, ArrowDownCircleIcon, ArrowLeftCircleIcon } from '@heroicons/react/24/solid'
import { selectLangObj } from '../reducers/langReducer';
import { useNavigate } from 'react-router';

function GridPreview({ }) {
    let params = useParams()
    const navigate = useNavigate();

    let id = params.id;

    const dispatch = useDispatch();
    const singleDocument = useSelector(selectSingleDocument);
    const langObj = useSelector(selectLangObj);

    useEffect(() => {

        dispatch(fetchSingleDocument(id));
        return () => {
            dispatch(resetSingleDocument());

          };
    }, []);



    return (
        <div className='w-full h-full backdrop-blur-xl p-3 md:p-6 overflow-auto flex flex-col'>
            <div className='rounded-xl flex flex-col overflow-hidden flex-grow' style={{ minHeight: 0 }}>
                <div className='bg-beige-800 bg-opacity-50 backdrop-blur-xl flex justify-center items-stretch flex-grow relative' style={{ minHeight: 0 }}>
                    <img className='object-contain h-full' src={singleDocument?.линк}></img>

                    <div className={'backdrop-blur-lg absolute top-0 left-0 w-full h-full  transition-all duration-300  ' + singleDocument?.линк?'opacity-0':' opacity-100'}>

                    </div>
                </div>
                <div className='bg-beige-400 px-3 md:px-12 py-1 md:py-3 flex justify-center items-stretch gap-3 md:gap-12'>
                    <div className='flex flex-col gap-6  text-slate-700'>
                        <div className='flex items-center gap-3 text-3xl'>

                            {singleDocument?.наслов}
                        </div>
                        <div className='flex items-center gap-3 text-lg'>

                            <UserCircleIcon className='size-6' />
                            {singleDocument?.прикупио}
                        </div>
                        <div className='flex items-center  gap-3'>


                            <MapPinIcon className='size-4' />
                            {singleDocument?.место}
                        </div>
                        <div className='flex items-center  gap-3'>


                            <CalendarDaysIcon className='size-4' />
                            {singleDocument?.датум}
                        </div>

                    </div>
                    <div className='flex flex-col  text-slate-700'>
                    </div>
                    <div className='flex flex-col  text-slate-700 items-start justify-start'>
                    <div className='text-3xl'>

                    {langObj["Options"]}
                    </div>
                    {/* <hr className='w-full border-slate-500'/> */}
                        <button className='text-xl transition-all duration-300 hover:scale-110  py-1 flex gap-1 items-center text-emerald-800'>
                            <ArrowDownCircleIcon className='size-8' />
                            {langObj["Download"]}
                        </button>
                  
                        <button onClick={() => navigate(-1)} className='text-lg transition-all duration-300 hover:scale-110 py-1 flex gap-1 items-center text-red-900'>
                            <ArrowLeftCircleIcon className='size-6' />
                            {langObj["Back"]}
                        </button>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default GridPreview;