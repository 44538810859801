import { fetchBooks } from '../fetch/documents';
import { createReducer } from './createReducer';
const books = createReducer("books", fetchBooks);


export const selectBooks = (state) => state.books.value
export const selectLoadingBooks = (state) => state.books.isLoading

export const {} = books.actions

export default books.reducer

