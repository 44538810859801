import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function GridElement({item}) {


    var [hovered, setHovered] = useState(false);
    return (
        <div className='flex flex-col '>
            <div onMouseEnter={() => setHovered(true)}
    onMouseLeave={() => setHovered(false)} className=' md:hover:scale-110  transition-all w-full relative'>
                <div className='w-full' style={{marginBottom:"162%"}}></div>
                <LazyLoadImage  effect="opacity" loading='lazy' className='h-full w-full object-cover absolute top-0 left-0' src={item.src} >


                </LazyLoadImage>

                <div  className={(hovered?'h-4/6 p-3 ' :'h-0') + ' text-white overflow-hidden flex flex-col  gap-1 items-start transition-all  backdrop-blur-sm bg-slate-700 bg-opacity-50 w-full absolute bottom-0 left-0'}>

                    {item.title}
                
                </div>
            </div>

            {/* MAYBE LATER */}
            {/* <div className='p-3 bg-stone-300'>
                {item.title}
            </div> */}
        </div>
    );
}

export default GridElement;