import * as Constants from '../Constants'
import { createAsyncThunk } from '@reduxjs/toolkit';


function CreateBasicThunk(name, path){
  return createAsyncThunk(name, async () => {

    //let pageSize = 10;
    try {
      var myHeaders = new Headers();
      myHeaders.append("X-Api-Key", "ApiKeyTest");
      myHeaders.append('Access-Control-Allow-Origin', Constants.PATH);
      myHeaders.append('Access-Control-Allow-Credentials', 'true');
    
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
        dataType:"json"
      };
      const res = await fetch(Constants.API_ROOT + path, requestOptions);
      return res?.json();
    } catch (error) {
      return [];
    }
    
  
  });


}


export const fetchRecentDocuments = CreateBasicThunk("fetchRecentDocuments", `/api/Documents/GetRecentDocuments`);
export const fetchMedia = CreateBasicThunk("fetchMedia", `/api/Documents/GetMedia`);
export const fetchBooks = CreateBasicThunk("fetchBooks", `/api/Documents/GetBooks`);
export const fetchDocuments = CreateBasicThunk("fetchDocuments", `/api/Documents/GetDocuments`);
export const fetchImages = CreateBasicThunk("fetchImages", `/api/Documents/GetImages`);
export const fetchRecentPosters = CreateBasicThunk("fetchRecentPosters",`/api/Posters/GetRecentPosters`);
