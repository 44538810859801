import { useRef, useState } from "react";
import SliderElement from "./SliderElement";

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { NavLink } from "react-router";
var winmd = window.matchMedia("(min-width: 768px)")

function Slider({ title, items, onEnd, endPercent, dynamic, moreTitle, moreLink }) {
    const liveSlide = dynamic !== false;
    const [scrollLeft, setScrollLeft] = useState(false);
    const [scrollRight, setScrollRight] = useState(true);
    const ref = useRef(null);
    const scrollStep = 200;


    const scroll = (scrollOffset) => {
        let left = ref.current.scrollLeft + scrollOffset;

        ref.current.scroll({
            top: 0,
            left: left,
            behavior: 'smooth'
        });
    };

    const endRatio = endPercent || 0.99;

    return (
        <div className="flex gap-1 flex-col w-full">

            <div className=" w-full flex items-center gap-3 relative">

                <div className=" p-3 flex gap-3 flex-col  flex-grow rounded-lg bg-slate-500 " style={{ minWidth: 0 }}>
                    <div className="flex gap-3 items-center">
                        <div className="text-white text-3xl self-start text-left">
                            {title}
                        </div>
                        <hr className="w-40 text-white"/>
                        <NavLink to="/Grid" end className="text-blue-950 font-semibold">
                            {moreTitle}
                        </NavLink>

                    </div>

                    <div className=" flex-grow overflow-x-auto flex gap-3 pb-3" ref={ref} onScroll={(e => {
                        if (e.currentTarget.scrollLeft > 0) {
                            setScrollLeft(true);
                        } else {
                            setScrollLeft(false);

                        }
                        let el = e.currentTarget;
                        if (el.scrollWidth - el.scrollLeft - el.clientWidth == 0) {
                            setScrollRight(false);
                        } else {
                            setScrollRight(true);

                        }

                        if ((liveSlide && (el.scrollLeft + el.clientWidth) / el.scrollWidth > endRatio)) {
                            onEnd();

                        }

                    })} >
                        {items()}
                    </div>
                </div>
                {

                    (scrollLeft && winmd.matches) &&
                    (

                        <div className="flex justify-center items-center h-full p-3 absolute md:left-5 left-1 ">

                            <button onClick={() => scroll(-scrollStep)} className="bg-beige-500 rounded-full text-slate-600 hover:bg-beige-700 p-3 transition-all hover:scale-110 ">
                                <ChevronLeftIcon className=" size-4 md:size-8 text-slate-600"></ChevronLeftIcon>
                            </button>
                        </div>


                    )
                }
                {

                    (scrollRight && winmd.matches) &&
                    (
                        <div className="flex justify-center items-center h-full p-3 absolute md:right-5 right-1 ">

                            <button onClick={() => scroll(scrollStep)} className="bg-beige-500 rounded-full  hover:bg-beige-700 p-3 transition-all hover:scale-110">
                                <ChevronRightIcon className="size-4 md:size-8 text-slate-600" />
                            </button>
                        </div>
                    )}
            </div>
        </div>

    );
}

export default Slider;
