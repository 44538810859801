import { createSlice } from "@reduxjs/toolkit";

export function createReducer(name, fetch){
    return createSlice({
        name: name,
        initialState: {
          isLoading: false,
          value: [],
          isError: false
        },
        extraReducers: (builder) => {
          builder.addCase(fetch.pending, (state, action) => {
            state.isLoading = true;
          })
          builder.addCase(fetch.fulfilled, (state, action) => {
            state.isLoading = false;
            let data = action.payload.items;
            if (data && data.constructor === Array){
              state.value = data;
            }
          })
          builder.addCase(fetch.rejected, (state, action) => {
            state.isError = true;
          })
        },
        reducers: {
        }
      })
}