class PagingDto {
    PageNumber: number;
    PageSize: number;
  
    constructor(PageNumber: number, PageSize: number) {
      this.PageNumber = PageNumber;
      this.PageSize = PageSize;
    }
  }
  
  interface FilterProperties {
    Тип: string;
    Име: string;
    Наслов: string;
    Датум: Date;
    Прикупио: string;
    Период: number;
    Село: string;
    Кућа: number;
    Презиме: string;
    Пол: string;
    ИмеОца: string;
    ИмеМајке: string;
    Супруг: string;
    Статус: string;
    УдатоПрезиме: string;
    ДевојачкоПрезиме: string;
    Позиција: string;
    ГодРођења: number;
    ГодСмрти: number;
    МестоРођења: string;
    Сахрањен: string;
  }
  
  class FilterDTO extends PagingDto implements FilterProperties {
    Тип: string;
    Име: string;
    Наслов: string;
    Датум: Date;
    Прикупио: string;
    Период: number;
    Село: string;
    Кућа: number;
    Презиме: string;
    Пол: string;
    ИмеОца: string;
    ИмеМајке: string;
    Супруг: string;
    Статус: string;
    УдатоПрезиме: string;
    ДевојачкоПрезиме: string;
    Позиција: string;
    ГодРођења: number;
    ГодСмрти: number;
    МестоРођења: string;
    Сахрањен: string;
  
    constructor(PageNumber: number, PageSize: number, FilterProperties: FilterProperties) {
      super(PageNumber, PageSize);
      this.Тип = FilterProperties.Тип || "";
      this.Име = FilterProperties.Име || "";
      this.Наслов = FilterProperties.Наслов || "";;
      this.Датум = FilterProperties.Датум || null;
      this.Прикупио = FilterProperties.Прикупио || "";;
      this.Период = FilterProperties.Период || NaN;
      this.Село = FilterProperties.Село || "";;
      this.Кућа = FilterProperties.Кућа || NaN;
      this.Презиме = FilterProperties.Презиме || "";;
      this.Пол = FilterProperties.Пол || "";;
      this.ИмеОца = FilterProperties.ИмеОца || "";;
      this.ИмеМајке = FilterProperties.ИмеМајке || "";;
      this.Супруг = FilterProperties.Супруг || "";;
      this.Статус = FilterProperties.Статус || "";;
      this.УдатоПрезиме = FilterProperties.УдатоПрезиме || "";;
      this.ДевојачкоПрезиме = FilterProperties.ДевојачкоПрезиме || "";;
      this.Позиција = FilterProperties.Позиција || "";;
      this.ГодРођења = FilterProperties.ГодРођења || NaN;
      this.ГодСмрти = FilterProperties.ГодСмрти || NaN;
      this.МестоРођења = FilterProperties.МестоРођења || "";;
      this.Сахрањен = FilterProperties.Сахрањен || "";;
    }
  }
  
export default FilterDTO;
