import React from 'react'
import Pagination from './Pagination'
import { selectPopulation,selectPage, selectTotalCount, selectTotalPages } from '../../../reducers/populationReducer';
import Row from "../../search/Row";
import { useDispatch, useSelector } from 'react-redux';
import { fetchPopulation } from '../../../fetch/population';

export default function PopulationPagination({term}) {

    const population = useSelector(selectPopulation);
    const page = useSelector(selectPage);
    const count = useSelector(selectTotalCount);
    const totalPages = useSelector(selectTotalPages);
    const dispatch = useDispatch();

    var getItems = e => {

        return population.map(a=>{ 
          

          return (<Row photo={a.documentId} title={a.име +(a.имеОца?" ("+a.имеОца+") ":" ")+ a.презиме} text={a.годРођења && (a.годРођења + (a.годСмрти?(" - "+a.годСмрти):"")) || ""}></Row>)


        });

      }

  return (
    <Pagination onPageSet={(e)=>{

      dispatch(fetchPopulation({surname:term, page:e}));


  }} count={count} getItems={getItems} pagenum={page} totalpages={totalPages} />
  )
}
