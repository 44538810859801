class SliderItem {
    constructor(title, image, url, date, author=null) {
        this.date = date;
        this.url = url;
        this.image = image;
        this.url =  url;
        this.title = title;
        this.author = author;
    }
}

export default SliderItem;