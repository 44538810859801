import * as Constants from '../Constants'
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchStreets = createAsyncThunk("fetchStreets", async ({surname, page}) => {

  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", "ApiKeyTest");
  myHeaders.append('Access-Control-Allow-Origin', Constants.PATH);
  myHeaders.append('Access-Control-Allow-Credentials', 'true');

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
    dataType:"json"
  };
  const res = await fetch(Constants.API_ROOT + `/api/DefaultSearch/GetKuceUUlici?` + new URLSearchParams({
    pageNumber:page,
    pageSize:20,
    streetname:surname
}), requestOptions);
  return res?.json();
});
