

function LangButton(props) {

  return (

    <button onClick={props.onClick} className={(props.selected ? "bg-beige-800" : "bg-beige-600") + ' transition-all duration-300 text-sm  px-5 max-md:py-5 hover:bg-beige-700'}>
      {props.name}
    </button>
  );
}

export default LangButton;
