import React from 'react'
import Pagination from './Pagination'
import { selectSchools,selectPage, selectTotalCount, selectTotalPages } from '../../../reducers/schoolsReducer';
import Row from "../../search/Row";
import { useDispatch, useSelector } from 'react-redux';
import { fetchSchools } from '../../../fetch/school';

export default function SchoolsPagination({term}) {

    const schools = useSelector(selectSchools);
    const page = useSelector(selectPage);
    const count = useSelector(selectTotalCount);
    const totalPages = useSelector(selectTotalPages);
    const dispatch = useDispatch();

    var getItems = e => {

        return schools.map(a=>{ 
          
          return (<Row  photo={a.documentId} title={a.назив +(a.годУ?" ("+a.годУ+") ":" ")} text={a.ознакаМеста}></Row>)
          });

      }

  return (
    <Pagination onPageSet={(e)=>{

      dispatch(fetchSchools({surname:term, page:e}));


  }} count={count} getItems={getItems} pagenum={page} totalpages={totalPages} />
  )
}
