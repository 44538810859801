import { selectRecentDocuments } from '../../reducers/recentDocumentsReducer.js';
import { fetchRecentDocuments } from '../../fetch/documents.js';
import { selectLoadingRecentDocuments } from '../../reducers/recentDocumentsReducer.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLangObj } from '../../reducers/langReducer.js';
import SliderElement from '../slider/SliderElement.js';
import SliderItem from '../../models/SliderItem.js';
import DocumentSlider from '../slider/DocumentSlider.js';


function RecentDocuments(props) {
  const recentDocuments = useSelector(selectRecentDocuments);
  const dispatch = useDispatch();
  const langObj = useSelector(selectLangObj);

  const appendItems = () => {
    dispatch(fetchRecentDocuments());
  }
  useEffect(() => {

    appendItems();
  }, []);

  function getItems(recentDocuments){


    return recentDocuments.map(e => { return new SliderItem( e.наслов, e.линк,e.линк,e.датум, e.прикупио); }).map(e => {

      return (
        <SliderElement auto={false} key={e.url} data={e}></SliderElement>
      )
    })
  }

  return (
    
    /* {
           "рбр": 24,
           "тип": "Фото",
           "име": "АЦ0001",
           "наслов": "фотографија",
           "датум": "2024-10-29",
           "прикупио": "С. Тришић",
           "период": 0,
           "линк": "https://i.ibb.co/sRBNGHV/img002.jpg",
           "линкП": "https://ibb.co/9gM1Ltr"

           {
                               items && items.map(e => {

                                   return (s
                                       <SliderElement data={e}></SliderElement>
                                   )
                               })
                           }
       }, */
    <DocumentSlider title={langObj["RecentDocuments"]} appendItems={appendItems} getItems={()=>getItems(recentDocuments)}  ></DocumentSlider>
  );
}

export default RecentDocuments;
