import { fetchImages } from '../fetch/documents';
import { createReducer } from './createReducer';
const recentDocumentsSlice = createReducer("images", fetchImages);


export const selectImages = (state) => state.images.value
export const selectLoadingImages = (state) => state.images.isLoading

export const {} = recentDocumentsSlice.actions

export default recentDocumentsSlice.reducer

