import React, { useEffect, useState } from 'react';
import FilterDTO from '../../models/Filters.ts';
import { InitialFilterProperties, resetGrid, selectFilter, setFilter } from "../../reducers/gridReducer.js"
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllDocuments } from '../../fetch/fetchAllDocuments.js';
import { useTimeout } from '../../utility/timeout.js';
import { selectLangObj } from '../../reducers/langReducer.js';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'

function GridSidePane(props) {
    const filter = useSelector(selectFilter);
    const dispatch = useDispatch();
    const [wait, setWait] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const langObj = useSelector(selectLangObj);
    var [expanded, setExpanded] = useState(false);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }
    
        function expandedFalse() {
            setExpanded(false);
        }
    
        window.addEventListener('resize', expandedFalse);
    
        // This is likely unnecessary, as the initial state should capture
        // the size, however if a resize occurs between initial state set by
        // React and before the event listener is attached, this
        // will just make sure it captures that.
        expandedFalse();
    
        // Return a function to disconnect the event listener
        return () => window.removeEventListener('resize', expandedFalse);
    }, [])
    
    
    useEffect(() => {
        if (wait) {
            clearTimeout(timeoutId);

            return;
        }

        setWait(true);

        setTimeoutId(setTimeout(() => {
            dispatch(fetchAllDocuments(filter));
            setWait(false);
        }, 500));

        // Cleanup function to clear the timeout if the component unmounts
        return () => { clearTimeout(timeoutId); setWait(false) };


    }, [filter]);

    const handleChange = (event) => {
        const { name, value, type } = event.target;
        let convertedValue;
        switch (type) {
            case 'number': convertedValue = Number(value);
                break;
            case 'date': convertedValue = new Date(value);
                break;
            default: convertedValue = value;
        }

        dispatch(setFilter({ ...filter, [name]: convertedValue }));
        dispatch(resetGrid);
    };

    const typeSwitch = function (input) {

        if (typeof input === 'string') {
            return "text";
        } else if (typeof input === 'number') {
            return "number";
        } else if (typeof input === 'boolean') {
            return "check";
        } else if (input instanceof Date) {
            return "date";
        } else if (input instanceof Array) {
            return "text";
        } else if (typeof input === 'object') {
            return "text";
        } else if (typeof input === 'undefined') {
            return "text";
        } else {
            return "text";
        }
    }
    return (
        <div className={'bg-stone-400  flex flex-col gap-3 items-stretch  md:h-full transition-all duration-300 '+ (expanded?'h-4/6':'h-1/5')} >
            <div className='text-3xl text-slate-800 text-left py-3 px-3'>
                {langObj["Filters"]}
            </div>
            <div className=' min-h-0 flex-grow'>
                <div className='bg-stone-400 flex flex-col gap-3 items-stretch h-full py-3 px-3 overflow-y-scroll'>

                    {

                        filter ? Object.keys(filter).map(key => (key !== "PageNumber" && key !== "PageSize" && (
                            <div className='flex' key={key}>
                                {/* <label className='rounded-s-lg px-3 py-1 bg-slate-800 text-white basis-1/4 flex-grow-0 flex-shrink-0 ' htmlFor={key}>{key}</label> */}

                                <input type={typeSwitch(filter[key])} placeholder={langObj[key]} className='transition-all duration-300 focus:mb-3 placeholder-slate-800 focus:outline-none focus:border-b-2 bg-transparent border-b border-b-slate-800 flex-grow w-72 ' id={key} name={key} value={filter[key]} onChange={handleChange} />
                            </div>))) : ""

                    }
                    
                    </div>



            </div>

            <div className='flex items-center justify-center'>
                <button onClick={()=>setExpanded(!expanded)}>
                {expanded?( <ChevronUpIcon className='size-10 transition-all duration-300 hover:scale-110 '></ChevronUpIcon>):<ChevronDownIcon className='size-10 transition-all duration-300 hover:scale-110 '></ChevronDownIcon>}

                </button>
            </div>
        </div>
    );
}

export default GridSidePane;