import { MagnifyingGlassIcon, UserGroupIcon, } from '@heroicons/react/24/solid'
import { GiFamilyTree } from "react-icons/gi";
import { FaPeopleRoof } from "react-icons/fa6";
import { GiPhotoCamera } from "react-icons/gi";
import { NavLink } from 'react-router';


function Row({ title, text, onClick, photo }) {

  return (
    <div onClick={onClick} className="flex items-stretch gap-3 p-3 border border-gray-700 bg-white  w-full">
    <div onClick={onClick} className="flex flex-grow items-start gap-3 flex-col ">
      <div className='flex gap-3 self-stretch items-center'>

        <h5>
          {title}
        </h5>
        <div className='flex-grow'>
        </div>
        
      </div>
      <hr className='border self-stretch border-slate-600'></hr>

      <p>
        {text}
      </p>

        </div>

        <div className='flex gap-6 items-center'>
          <MagnifyingGlassIcon className='size-10'></MagnifyingGlassIcon>
          <FaPeopleRoof className='size-10'></FaPeopleRoof>
          <GiFamilyTree className='size-10'></GiFamilyTree>
          <NavLink  to={"/Document/" + photo} end>
          <GiPhotoCamera className='size-10' />
          </NavLink>

          

        </div>
    </div>
  );
}

export default Row;
