import { selectRecentPosters } from '../../reducers/recentPostersReducer.js';
import { selectLoadingRecentPosters} from '../../reducers/recentPostersReducer.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLangObj } from '../../reducers/langReducer.js';
import SliderCompositeElement from '../slider/SliderCompositeElement.js';
import SliderItem from '../../models/SliderItem.js';
import SliderComposite from '../../models/SliderComposite.js';
import { fetchRecentPosters } from '../../fetch/documents.js';
import DocumentSlider from '../slider/DocumentSlider.js';


function RecentPosters(props) {
  const loadingRecentPosters = useSelector(selectLoadingRecentPosters);
  const recentPosters = useSelector(selectRecentPosters);
  const dispatch = useDispatch();
  const langObj = useSelector(selectLangObj);
 
  const appendItems = ()=>{
    dispatch(fetchRecentPosters());
  }
  useEffect(() => {

    appendItems();
  },[]);
  
  function getItems(recentDocuments){
    return recentDocuments.map(e => { return new SliderComposite(e.user,e.документ.map(e => { return new SliderItem( null, e.линк,e.линк,null); })) }).map(e => {

      return (
        <SliderCompositeElement key={e.url} data={e}></SliderCompositeElement>
      )
    })
  }

  return (
     /* {
            "рбр": 24,
            "тип": "Фото",
            "име": "АЦ0001",
            "наслов": "фотографија",
            "датум": "2024-10-29",
            "прикупио": "С. Тришић",
            "период": 0,
            "линк": "https://i.ibb.co/sRBNGHV/img002.jpg",
            "линкП": "https://ibb.co/9gM1Ltr"
        }, */
           <DocumentSlider  dynamic={false} title={langObj["Posters"]} onEnd={()=>appendItems()} getItems={()=>getItems(recentPosters)}></DocumentSlider>
  );
}

export default RecentPosters;
