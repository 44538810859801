import { selectDocuments } from '../../reducers/documentsReducer.js';
import { fetchDocuments } from '../../fetch/documents.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLangObj } from '../../reducers/langReducer.js';
import SliderElement from '../slider/SliderElement.js';
import SliderItem from '../../models/SliderItem.js';
import DocumentSlider from '../slider/DocumentSlider.js';


function DocumentsSlider(props) {
  const documents = useSelector(selectDocuments);
  const dispatch = useDispatch();
  const langObj = useSelector(selectLangObj);

  const appendItems = () => {
    dispatch(fetchDocuments());
  }
  useEffect(() => {

    appendItems();
  }, []);

  function getItems(recentDocuments){


    return recentDocuments.map(e => { return new SliderItem( e.наслов, e.линкП,e.линк,e.датум, e.прикупио); }).map(e => {

      return (
        <SliderElement auto={false} key={e.url} data={e}></SliderElement>
      )
    })
  }

  return (
    
 
    <DocumentSlider title={langObj["Documents"]} appendItems={appendItems} getItems={()=>getItems(documents)}  ></DocumentSlider>
  );
}

export default DocumentsSlider;
