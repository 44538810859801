import { useEffect, useState } from "react";

var interval = null;
var images = Array.from({ length: 4 }, (_, i) => (i + 1).toString());



function BackGround() {

    const [image, setImage] = useState("1");
    const [transition, setTransition] = useState(false);

    // useEffect(() => {
    //     function backgroundJob() {
    //       let slika = Math.floor(Math.random() * 4) + 1;
    //       let broj = slika;
    //       // let broj = slika.toString().padStart(2, '0');
    //       setTransition(true);
    //       setTimeout(() => {
    
    //         setImage(broj);
    
    //       }, 200);
    //       setTimeout(() => {
    //         setTransition(false);
    
    
    //       }, 400);
    //     }
    //     let bginterval = setInterval(backgroundJob, 10000);
    //     return () => clearInterval(bginterval);
    
    //   });
    return (
        <div className="absolute top-0 left-0 w-full h-full">

        {
            images.map((a, i) => {
        
        
              return (
                <img
                  // src={"https://www.mb51p.com/foto/TT/TT" + a + ".jpg"}
                  src={require("../images/" + a + ".jpg")}
                  class={(a == image ? "opacity-100 " : " opacity-0 ") + "  transition-opacity duration-1000 absolute top-0 left-0 w-full h-full object-cover"}
                />);
            })
          }
          <div
            // src={"https://www.mb51p.com/foto/TT/TT" + a + ".jpg"}
        
            class={(transition ? "opacity-100 " : " opacity-0 ") + " z-1 transition-opacity duration-1000 absolute top-0 left-0 w-full h-full object-cover backdrop-blur-lg"}
          />
        </div>
        
    );
  }
  
  export default BackGround;
  