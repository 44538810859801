
import Slider from './Slider.js';
import { selectLangObj } from '../../reducers/langReducer.js';
import { useSelector } from 'react-redux';


function DocumentSlider({title, appendItems, getItems, moreTitle, moreLink }) {

  const langObj = useSelector(selectLangObj);

  return (
    <Slider dynamic={false} moreTitle={moreTitle||langObj["SeeMore"]} moreLink={moreLink} title={title} onEnd={() => appendItems()} items={()=>getItems()}></Slider>
  );
}

export default DocumentSlider;
