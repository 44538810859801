import * as Constants from '../Constants'
import { setFamily } from '../reducers/familiesReducer'
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchFamilies = createAsyncThunk("fetchFamilies", async ({surname, page}) => {


  try {
    var myHeaders = new Headers();
    myHeaders.append("X-Api-Key", "ApiKeyTest");
    myHeaders.append('Access-Control-Allow-Origin', Constants.PATH);
    myHeaders.append('Access-Control-Allow-Credentials', 'true');
  
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
      dataType:"json"
    };
    const res = await fetch(Constants.API_ROOT + `/api/DefaultSearch/GetPorodice?` + new URLSearchParams({
      pageNumber:page,
      pageSize:20,
      surname:surname
  }), requestOptions);
    return res?.json();
  } catch (error) {
    return [];
  }
  

});

// export function fetchFamily() {
//   return dispatch => {
//   //   dispatch(setLoadingBookState()); // Show a loading spinner
//       fetch(`/api/GetPorodice`, (response) => {
//       //dispatch(doneFetchingBook()); // Hide loading spinner
//       if(response.status == 200){
//         dispatch(setFamily(response.json)); // Use a normal function to set the received state
//       }else { 
//         dispatch(someError)
//       }
//     })
//   }
//  }
 
//  function setFamily(data) {
//   return { type: 'SET_FAMILY', data: data };
//  }