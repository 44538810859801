import { configureStore } from '@reduxjs/toolkit'

import monitorReducersEnhancer from './enhancers/monitorReducer'
import loggerMiddleware from './middleware/logger'
import counterReducer from './reducers/familiesReducer'
import populationReducer from './reducers/populationReducer'
import schoolsReducer from './reducers/schoolsReducer'
import streetsReducer from './reducers/streetsReducer'
import langReducer from './reducers/langReducer'
import loaderReducer from './reducers/loaderReducer'
import recentDocuments from './reducers/recentDocumentsReducer'
import recentPosters from './reducers/recentPostersReducer'
import booksReducer from './reducers/booksReducer'
import mediaReducer from './reducers/mediaReducer'
import imagesReducer from './reducers/imagesReducer'
import documentsReducer from './reducers/documentsReducer'
import gridReducer from './reducers/gridReducer'

export default function configureAppStore(preloadedState) {
  const store = configureStore({
    reducer: {
      
      families:counterReducer,
      population:populationReducer,
      streets:streetsReducer,
      schools:schoolsReducer,
      lang:langReducer,
      loader:loaderReducer,

      recentDocuments:recentDocuments,
      books:booksReducer,
      media:mediaReducer,
      images:imagesReducer,
      documents:documentsReducer,
      recentPosters:recentPosters,

      grid:gridReducer
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().prepend(loggerMiddleware),
    preloadedState,
    // enhancers: [monitorReducersEnhancer]
  })

  // if (process.env.NODE_ENV !== 'production' && module.hot) {
  //   module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
  // }

  return store
}