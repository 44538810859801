import { useDispatch, useSelector } from "react-redux";
import GridSidePane from "../components/grid/GridSidePane";
import { selectRecentDocuments } from '../reducers/recentDocumentsReducer';
import { fetchAllDocuments } from '../fetch/fetchAllDocuments.js';
import { selectLangObj } from "../reducers/langReducer.js";
import { useEffect } from "react";
import GridElement from "../components/grid/GridElement.js";
import { NavLink } from "react-router";

import { resetGrid, selectDocuments, selectPage, selectTotalCount, selectTotalPages, selectLoadingSingleDocument, selectFilter, setPage, resetSingleDocument } from "../reducers/gridReducer.js"
import FilterDTO from "../models/Filters.ts";

function Grid() {
    const documents = useSelector(selectDocuments);
    const loading = useSelector(selectLoadingSingleDocument);
    const dispatch = useDispatch();
    const langObj = useSelector(selectLangObj);
    const endRatio = 0.95;

    const page = useSelector(selectPage);
    const count = useSelector(selectTotalCount);
    const totalPages = useSelector(selectTotalPages);
    const filter = useSelector(selectFilter);


    const appendItems = (newpage = null) => {

        // Setting up filterProperties object with empty strings
        if (newpage) {
            dispatch(setPage(newpage));

        }



        dispatch(fetchAllDocuments(filter));
    }

    useEffect(() => {

        dispatch(resetGrid);
        appendItems();
    }, []);

    return (

        <div className="h-full flex flex-col md:flex-row relative">
            <GridSidePane>

            </GridSidePane>

            <div className="flex-grow grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-3 md:gap-12 p-6 md:p-12 backdrop-blur-md   h-full overflow-y-scroll" onScroll={(e => {
                let el = e.currentTarget;


                if ((el.scrollTop + el.clientHeight) / el.scrollHeight > endRatio) {
                    if (!loading) {

                        appendItems(page + 1);
                    }
                }

            })}>
                {

documents?(documents.map(e => (
    <NavLink to={"/Document/" + e.рбр} end>
        <GridElement item={{ title: e.наслов, src: e.линк }}></GridElement>
    </NavLink>
))):""

                }
            </div>
        </div>






    )
}
export default Grid;