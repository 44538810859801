import * as Constants from '../Constants'
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchAllDocuments = createAsyncThunk("fetchAllDocuments", async (filter ) => {

  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", "ApiKeyTest");
  myHeaders.append('Access-Control-Allow-Origin', Constants.PATH);
  myHeaders.append('Access-Control-Allow-Credentials', 'true');
  myHeaders.append('Content-Type', 'application/json');
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    dataType: "json",
    body: JSON.stringify(filter)

  };
  const res = await fetch(Constants.API_ROOT + `/api/Documents/AllDocuments`, requestOptions);
  return res?.json();
});
