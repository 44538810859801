import { selectMedia } from '../../reducers/mediaReducer.js';
import { fetchMedia } from '../../fetch/documents.js';
import { selectLoadingRecentDocuments } from '../../reducers/recentDocumentsReducer.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLangObj } from '../../reducers/langReducer.js';
import SliderElement from '../slider/SliderElement.js';
import SliderItem from '../../models/SliderItem.js';
import DocumentSlider from '../slider/DocumentSlider.js';


function MediaSlider(props) {
  const media = useSelector(selectMedia);
  const dispatch = useDispatch();
  const langObj = useSelector(selectLangObj);

  const appendItems = () => {
    dispatch(fetchMedia());
  }
  useEffect(() => {

    appendItems();
  }, []);

  function getItems(recentDocuments){


    return recentDocuments.map(e => { return new SliderItem( e.наслов,e.линкП, e.линк,e.датум, e.прикупио); }).map(e => {

      return (
        <SliderElement auto={false} key={e.url} data={e}></SliderElement>
      )
    })
  }

  return (
    
 
    <DocumentSlider title={langObj["Media"]} appendItems={appendItems} getItems={()=>getItems(media)}  ></DocumentSlider>
  );
}

export default MediaSlider;
