
import { useSelector } from 'react-redux';
import { useState, useMemo } from 'react';
import { selectLangObj } from '../../reducers/langReducer';
import FamilyPagination from './forms/FamilyPagination';
import PopulationPagination from './forms/PopulationPagination';
import StreetsPagination from './forms/StreetsPagination';
import SchoolsPagination from './forms/SchoolsPagination';



function SearchLayout({Type, term}) {

  const langObj = useSelector(selectLangObj);

  const [detail, setDetails] = useState(null);



  const switchRows = () => {

    switch (Type) {
      case "family":
        return (<FamilyPagination term={term}></FamilyPagination>)
      case "people":
        return (<PopulationPagination term={term}></PopulationPagination>)
      case "streets":
        return (<StreetsPagination term={term}></StreetsPagination>)
      case "school":
        return (<SchoolsPagination term={term}/>)
      default:
        return "";

    }


  }
  return (


    <div className="flex gap-6 flex-grow items-stretchrelative w-full" style={{ minHeight: 0 }}>

      <div className=' h-full flex-grow rounded-lg backdrop-blur-xl bg-slate-500 bg-opacity-50 border  p-3 border-gray-600 flex flex-col'>

        {
          switchRows()
        }
        </div>
      {/* <div className='text-white rounded-lg backdrop-blur-xl bg-slate-500 border border-gray-600 basis-1/3 flex-shrink-0 p-3 flex flex-col gap-3'>
        <div className='flex items-end gap-3'>
          <div className='rounded-full relative overflow-hidden' style={{ height: '2rem', width: '2rem' }}>
            <img src={require("../images/user.png")} className='absolute w-full h-full top-0 left-0'></img>
          </div>
          <h3>
            Титле
          </h3>
        </div>
        {detail && <hr className='border self-stretch border-slate-600'></hr>}

        <div className='flex flex-col items-start gap-3'>

          {
            detail &&
            Object.keys(detail).map(e => {
              return (<div className=''><b>{e}</b> - {detail[e]} </div>)
            })
          }
        </div>
      </div> */}
    </div>
  );
}

export default SearchLayout;
