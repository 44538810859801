import './App.css';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectLoadingFamilies } from './reducers/familiesReducer.js';
import { selectLoadingStreets } from './reducers/streetsReducer.js';
import { selectLoadingSchools } from './reducers/schoolsReducer.js';
import { selectLoadingPopulation } from './reducers/populationReducer.js';
import { selectLangObj } from './reducers/langReducer.js';
import { selectLoader, setLoading } from './reducers/loaderReducer.js';
import BackGround from './components/Background.js';
import Header from './Header.js';
import { BrowserRouter, Route, Routes } from 'react-router';
import Home from './Pages/Home.js';
import Grid from './Pages/Grid.js';
import GridPreview from './Pages/GridPreview.js';

// Create a MediaQueryList object


function App() {

  const langObj = useSelector(selectLangObj);
  const loading = useSelector(selectLoader);
  const loadingFamilies = useSelector(selectLoadingFamilies);
  const loadingStreets = useSelector(selectLoadingStreets);
  const loadingSchools = useSelector(selectLoadingSchools);
  const loadingPopulation = useSelector(selectLoadingPopulation);


  //dispatch(fetchFamilies());


  useEffect(() => {
    document.title = langObj["AppTitle"];
  });







  return (
    <div className="App flex flex-col">


      <div className='h-screen flex flex-col  relative '>
        {
          loading || loadingFamilies || loadingPopulation || loadingSchools || loadingStreets ?
            (
              <div className='w-full h-full backdrop-blur-lg  absolute top-0 left-0 z-50 flex justify-center items-center'>
                <div className="loader"></div>
              </div>
            ) :
            ""
        }

        <Header />


        <div className={" justify-center  transition-all  flex-grow flex gap-3 flex-col relative"} style={{ minHeight: 0 }} >


          <BackGround />
          {/* <img src={"https://www.mb51p.com/foto/TT/TT" + image + ".jpg"} class="absolute top-0 left-0 w-full h-full object-cover"></img> */}
          {/* <img src={logo} className="App-logo" alt="logo" /> */}
          <div className='w-full h-full overflow-y-scroll z-1 isolate  '>

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Grid" element={<Grid />} />
              <Route path="/Document/:id" element={<GridPreview key={"id"}/>}/>

            </Routes>

            <footer className='flex justify-end gap-3 p-1 bg-gray-700 text-gray-300'>
              <span className='text-white'>
                12.12.2024
              </span>

              Arhiva Gajdobra co.
            </footer>
          </div>
        </div>
      </div>

    </div>
  );
}

export default App;
